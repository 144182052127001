<template>
    <div class="home-page">
        <Header/>
        <div class="banner">
            <img class="banner-img" src="../assets/aboutus/banner.png">
            <div class="check-block">
                <div :class="selectIndex===0?'selected c-item':'c-item'" @click="switchIndex(0)">
                    <img class="sel-img" src="../assets/aboutus/sfz.png">公司介绍
                </div>
                <div :class="selectIndex===1?'selected c-item':'c-item'" @click="switchIndex(1)">
                    <img class="sel-img" src="../assets/aboutus/jiangbei.png">荣誉资质
                </div>
                <div :class="selectIndex===2?'selected c-item':'c-item'" @click="switchIndex(2)">
                    <img class="sel-img" src="../assets/aboutus/xh.png">加入我们
                </div>
                <div :class="selectIndex===3?'selected c-item':'c-item'" @click="switchIndex(3)">
                    <img class="sel-img" src="../assets/aboutus/tele.png">联系方式
                </div>
            </div>
        </div>
        <div class="main-page">
            <div v-if="selectIndex===0||selectIndex===1" class="block-0">
                <div class="title">{{selectIndex===0?'公司介绍':'荣誉资质'}}</div>
                <div class="txt-content" v-html="selectIndex===0?globalConfig.company_introduction.content:globalConfig.honorary_qualification.content" />
            </div>

            <div v-if="selectIndex===2" class="block-0">
                <div class="title" style="margin-bottom:0px">加入我们</div>
                <div class="subtitle">简历投提邮箱：liulu@shouguanedu.cn(邮件标题：[职位名称]+[姓名])</div>
                <div class="list-content" >
                    <div v-for="(item, index) in usList" :key="index" :style="{marginLeft:index%2===1?'15px':'0px'}" class="us-item" @click="clickItem(item)">
                        <div class="line"/>
                        <div class="txt1">{{item.name}}</div>
                        <div class="txt2">{{item.content}}</div>
                    </div>
                </div>
            </div>

            <div v-if="selectIndex===3" class="block-0">
                <div class="title" style="margin-bottom:0px">联系方式</div>
                <div class="tele-content" >
                    <div>
                        <img src="../assets/contact1.png">
                        <div>客服热线</div>
                        <div>+86 010-64508600</div>
                    </div>
                    <div>
                        <img src="../assets/contact2.png">
                        <div>服务传真</div>
                        <div>+86 010-64508402</div>
                    </div>
                    <div>
                        <img src="../assets/contact3.png">
                        <div>企业邮箱</div>
                        <div>oneplusx@shouguan.cn</div>
                    </div>
                    <div>
                        <img src="../assets/contact4.png">
                        <div>公司地址</div>
                        <div>北京市丰台区南三环西路16号</div>
                        <div>搜宝商务中心3号楼11层</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'globalConfig',
    ]),
    data() {
        return {
            selectIndex:0,
            usList:[],
        }
    },
    mounted() {
        if(this.$route.query.index){
            this.selectIndex = Number(this.$route.query.index);
        }
        this.getData();
    },
    methods: {
        switchIndex(index){
            this.selectIndex = index;
        },
        getData(){
            this.$http.get('/api/index/getConfigList',{type:'join_us'}).then(res => {
                console.log(res,'res');
                if(res){
                    this.usList = res;
                }
            })
        },
        clickItem(item){
            const that = this;
            this.$http.get('/api/index/getConfigDetail',{id:item.id}).then(res => {
                console.log(res,'res');
                if(res){
                    that.$notify({
                        title: res.name,
                        dangerouslyUseHTMLString: true,
                        message: res.content,
                        duration: 0,
                        customClass: 'myalert',
                    });
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.flex{
    display: flex;
    justify-content: space-between;
}


.banner{
    width:100%;
    height: 280px;
    position: relative;
    .banner-img{
        position: absolute;
        width:1920px;
        height: 280px;
        left: calc(50% - 960px);
    }
    .check-block{
        width:1200px;
        height:84px;
        position: absolute;
        bottom:0px;
        background: #FAFAFA;
        left: calc(50% - 600px);
        z-index:999;
        box-sizing: border-box;
        padding: 0 83px;
        line-height: 78px;
        display: flex;
        justify-content: space-between;
        .c-item{
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #000000;
            cursor: pointer;
        }
        .selected{
            color: #4970C7 !important;
            position: relative;
        }
        .selected::before{
            content:'';
            position: absolute;
            width: 105px;
            bottom:0px;
            height:1px;
            background: #4970C7;
            left:0px;
        }
        .sel-img{
            height:24px;
            vertical-align: middle;
            margin-right: 12px;
        }
    }
}

.main-page{
    width: 1200px;
    margin: auto;
}

.block-0{
    .title{
        text-align: center;
        height: 32px;
        font-size: 32px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        color: #000000;
        margin: 40px 0 28px 0;
    }
    .subtitle{
        text-align: center;
        height: 18px;
        font-size: 16px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #666666;
        margin: 12px 0 35px 0;
    }
    .txt-content{
        width:100%;
        box-sizing: border-box;
        padding: 0 55px;
    }
    .list-content{
        width:100%;
        box-sizing: border-box;
        padding: 0 55px;
        display: flex;
        flex-wrap: wrap;
        .us-item{
            width: calc(50% - 8px);
            height: 80px;
            border: 1px solid #EEEEEE;
            position: relative;
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            box-sizing: border-box;
            padding-left: 10px;
            margin-bottom: 22px;
            cursor: pointer;
            .line{
                height:80px;
                position: absolute;
                width: 4px;
                height: 81px;
                background: #3F66C3;
                left: -1px;
                top: -1px;
            }
            .txt1{
                color: #3F66C3;
                margin-top: 12px;
            }
            .txt2{
                color: #666666;
                margin-top: 10px;
            }
        }
    }

    .tele-content{
        display: flex;
        margin-top: 70px;
        box-sizing: border-box;
        padding: 0 70px;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
        img{
            height: 40px;
            margin-bottom: 5px;
        }
        div{
            // max-width:100px;
            text-align: center;
        }
    }
}
</style>
<style>
.myalert{
    left: calc(50% - 165px) !important;
    top: calc(50% - 30px) !important;
}
</style>
